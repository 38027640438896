<template>
  <base-section
    id="our-workflow"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
          >
            <base-info-card
              title="Our Service"
              align="center"
              class="white--text text-uppercase"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionOurWorkflow',

    data: () => ({
      cards: [
        {
          icon: 'far fa-book',
          title: 'Established Aptamers',
          text: 'Select your product from our evergrowing catalog of apatamers.',
        },
        {
          icon: 'far fa-microscope',
          title: 'Custom Aptamers',
          text: 'Custom Aptamers - designed specifically for your needs.',
        },
        {
          icon: 'fal fa-user-headset',
          title: '24/7 Support',
          text: 'Got Problems with your Aptamers? We\'ll figure it out.',
        },
      ],
    }),
  }
</script>
